html, body {
  margin: 0;
  padding: 0;
}

body {
  //height: 100vh;
}

#root {
  height: 100%;
  width: 100%;
}
